<template>
  <v-card
    :color="color"
    :elevation="value ? 12 : 0"
    class="pa-2"
    outlined
    tile
  >
    <v-card
      flat
      outlined
      tile
    >
      <div class="pa-6 pb- mb-4">
        <base-title
          :title="getSelectedTrail.text"
          class="text-uppercase"
          space="1"
        />

        <base-subtitle
          :title="getSelectedTrail.description"
          space="8"
        />
      </div>

      <div class="mb-3" />

      <div class="pb-4">
        <div
          v-if="!seasonalTrails"
          class="px-8 mb-2"
        >
          <template v-for="(pub, i) in getPubsFromSelectedTrail">
            <div
              :key="pub.id"
              style="cursor: pointer"
              class="my-3"
              @click="selectPub(pub)"
              v-text="pub.name"
            />

            <v-divider
              v-if="i + 1 < getPubsFromSelectedTrail.length"
              :key="`divider-${i}`"
            />
          </template>
        </div>
        <div
          v-else
          class="px-8 mb-2"
        >
          <template v-for="(item, i) in getSeasonalItemsFromSelectedTrail">
            <div
              :key="item.id"
              style="cursor: pointer"
              class="my-3"
            />
            {{ computeDate(item.date) }} {{ item.time }} -
            <b :key="`divider-bold-${i}`">{{ getPubName(item.pub) }}</b> {{ item.act }}

            <v-divider
              v-if="i + 1 < getSeasonalItemsFromSelectedTrail.length"
              :key="`divider-2-${i}`"
            />
          </template>
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'TrailDetailCard',

    provide: {
      heading: { align: 'center' },
    },

    props: {
      features: {
        type: Array,
        default: () => [],
      },
      price: [Number, String],
      subtitle: String,
      title: String,
      interval: {
        type: String,
      },
      value: Boolean,
      seasonalTrails: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters([
        'getSelectedTrail',
        'getPubsFromSelectedTrail',
        'getSeasonalItemsFromSelectedTrail',
        'getPubList',
      ]),
      color () {
        if (!this.value) return undefined

        return this.$vuetify.isDark ? 'white' : 'grey lighten-5'
      },
    },
    methods: {
      selectPub (pub) {
        this.$emit('pubSelected', pub)
      },
      computeDate (date) {
        if (date === '25/10/2024') {
          return 'Fri'
        } else if (date === '26/10/2024') {
          return 'Sat'
        } else if (date === '27/10/2024') {
          return 'Sun'
        } else if (date === '28/10/2024') {
          return 'Mon'
        }
      },
      getPubName (id) {
        return this.getPubList.find((pub) => pub.id === id).name
      },
      itemText (item) {
        const pub = this.getPubsFromSelectedTrail.find(
          (pub) => pub.id === item.pub,
        )
        return item.time + ' - ' + pub.name + ' ' + item.act
      },
    },
  }
</script>
