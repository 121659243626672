<template>
  <base-section id="trails">
    <v-container>
      <v-row>
        <!-- <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
            />

            <v-divider
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
          <v-row>
            <v-col>
              <span class="mr-3 text--subtitle-1">Outdoor Seating</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.outdoorSeating"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.outdoorSeating == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="mr-3 text--subtitle-1">Serving Food</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.food"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.food == item.value ? 'primary white--text' : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="mr-3 text--subtitle-1">Live Music</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.liveMusic"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.liveMusic == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="mr-3 text--subtitle-1">Cocktails</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.cocktails"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.cocktails == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="mr-3 text--subtitle-1">Craft Beer</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.craftBeer"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.craftBeer == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <base-btn
                class="mt-2"
                :color="!theme.isDark ? 'accent' : 'white'"
                outlined
                :small="true"
              >
                Reset
              </base-btn>
            </v-col>
            <v-col>
              <base-btn
                class="mb-12 mt-2"
                :small="true"
                @click="filterTrails(filters)"
              >
                Filter
              </base-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col> -->

        <v-col
          cols="12"
          md="12"
        >
          <base-subheading
            size="display-1"
            title="Trails"
            space="0"
          />

          <base-title
            class="primary--text"
            title="DISCOVER CORK"
            tag="div"
          />

          <v-select
            v-model="selectedTrail"
            class="mt-3"
            outlined
            clearable
            return-object
            label="Select a trail"
            :items="getTrailList"
            @input="trailSelected()"
          >
            <template v-slot:item="{ item }">
              {{ item.text }}
            </template>
          </v-select>
          <base-map
            ref="map"
            :large-map="true"
          />
          <trail-detail-card
            v-if="getSelectedTrail"
            id="trailDetailCard"
            @pubSelected="selectPub"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import BaseMap from '../../components/map/BaseMap.vue'
  import TrailDetailCard from '../../components/TrailDetailCard.vue'
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'SectionTrails',
    inject: ['theme'],
    components: { BaseMap, TrailDetailCard },
    data: () => ({
      cards: [
        {
          title: 'Find a Trail',
          icon: 'mdi-magnify',
          outlined: true,
        },
      ],
      options: [
        { value: 0, name: 'Yes' },
        { value: 1, name: 'No' },
        { value: 2, name: "Don't Mind" },
      ],
      filters: {
        outdoorSeating: 2,
        food: 2,
        liveMusic: 2,
        craftBeer: 2,
        cocktails: 2,
      },
      selectedTrail: null,
    }),
    computed: {
      ...mapGetters(['getTrailList', 'getSelectedTrail']),
    },
    mounted () {
      this.updateSelectedTrail(null)
    },
    methods: {
      ...mapActions(['updateSelectedTrail']),
      filterTrails (filters) {
        console.log(filters)
      },
      trailSelected () {
        this.updateSelectedTrail(this.selectedTrail)
        if (this.selectedTrail) {
          this.$nextTick(function () {
            document
              .getElementById('trailDetailCard')
              .scrollIntoView({ behavior: 'smooth', block: 'end' })
          })
        }
      },
      selectPub (pub) {
        this.$refs.map.selectPub(pub)
      },
    },
  }
</script>

<style>
@import "https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css";
</style>
