<template>
  <div id="homeMap">
    <div
      id="mapContainer"
      class="basemap black--text"
      :style="largeMap ? 'height: 550px' : null"
    />
  </div>
</template>

<script>
  import mapboxgl from 'mapbox-gl'
  import { mapGetters } from 'vuex'
  export default {
    name: 'BaseMap',
    props: {
      largeMap: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        accessToken:
          'pk.eyJ1IjoiY29ya3B1Ymd1aWRlIiwiYSI6ImNrcDVzNWJodjA0bngydW9meWYxOHMyM3MifQ.rY7zSF8FzujFNUdKo0VzHw',
        map: null,
        markers: [],
      }
    },
    computed: {
      ...mapGetters(['getPubsFromSelectedTrail', 'getSelectedTrailId']),
    },
    watch: {
      getSelectedTrailId () {
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].remove()
        }
        this.markers = []
        this.addPubs()
      },
    },
    mounted () {
      mapboxgl.accessToken = this.accessToken

      this.map = new mapboxgl.Map({
        container: 'mapContainer',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-8.4766, 51.8985],
        maxBounds: [
          [-8.49916, 51.8905],
          [-8.4466, 51.910],
        ],
      })
      this.addPubs()
    },
    methods: {
      addPubs () {
        for (let i = 0; i < this.getPubsFromSelectedTrail.length; i++) {
          const pub = this.getPubsFromSelectedTrail[i]
          const marker = new mapboxgl.Marker()
            .setLngLat([pub.lng, pub.lat])
            .setPopup(
              new mapboxgl.Popup({
                className: 'apple-popup',
                scale: 0.1,
              }).setHTML(pub.name),
            )
            .addTo(this.map)
          marker.id = pub.id
          this.markers.push(marker)
        }
      },
      selectPub (pub) {
        this.getPubsFromSelectedTrail.find(
          (it) => it.id === pub.id,
        )
        const marker = this.markers.find((it) => it.id === pub.id)
        marker.getElement().click()
        marker.getElement().scrollIntoView({ behavior: 'smooth', block: 'center' })
      },
    },
  }
</script>

<style scoped>
.basemap {
  width: 100%;
  height: 450px;
  border-style: solid;
  border-radius: 3px;
}
.apple-popup {
  color: black;
}
</style>
